import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "why-choose-zzeeh-weddings-for-budget-friendly-decor",
      label: "Why Choose Zzeeh Weddings for Budget-Friendly Decor",
    },
    {
      id: "setting-the-budget-for-wedding-decor",
      label: "Setting the Budget for Wedding Decor",
    },
    {
      id: "traditional-decor-elements-for-hindu-weddings",
      label: "Traditional Decor Elements for Hindu Weddings",
    },
    {
      id: "budget-friendly-wedding-theme-ideas",
      label: "Budget-Friendly Wedding Theme Ideas",
    },
    {
      id: "floral-arrangements-on-a-budget",
      label: "Floral Arrangements on a Budget",
    },
    {
      id: "elegant-mandap-decor-on-a-budget",
      label: "Elegant Mandap Decor on a Budget",
    },
    {
      id: "lighting-arrangements-within-budget",
      label: "Lighting Arrangements within Budget",
    },
    {
      id: "reusing-decor-elements-for-different-rituals",
      label: "Reusing Decor Elements for Different Rituals",
    },
    {
      id: "budget-friendly-seating-arrangements",
      label: "Budget-Friendly Seating Arrangements",
    },
    {
      id: "diy-decor-ideas-for-personal-touch",
      label: "DIY Decor Ideas for Personal Touch",
    },
    {
      id: "simple-entrance-and-aisle-decor",
      label: "Simple Entrance and Aisle Decor",
    },
    {
      id: "decorating-the-dining-area-on-a-budget",
      label: "Decorating the Dining Area on a Budget",
    },
    { id: "minimalist-stage-decor", label: "Minimalist Stage Decor" },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Flowers",
      body: "Marigolds, roses, and jasmine are classic choices. Using flowers sparingly but strategically, Zzeeh arranges these blooms to bring color and authenticity without inflating costs.",
    },
    {
      header: "Sacred Symbols and Traditional Motifs",
      body: "Incorporating Om symbols, lotus motifs, or paisley patterns in the decor adds a spiritual touch.",
    },
  ];

  const list2 = [
    {
      header: "Minimalist Traditional Theme",
      body: "Keep decor simple, with focus on essential elements like the mandap and aisle.",
    },
    {
      header: "Rustic Temple-Inspired Setup",
      body: "Using natural materials and simple floral arrangements, this theme brings a serene ambiance.",
    },
    {
      header: "Eco-Friendly Decor",
      body: "Zzeeh incorporates reusable or recyclable items, adding a contemporary, sustainable touch to traditional decor.",
    },
  ];

  const list3 = [
    {
      header: "Simple Garlands and Centerpieces",
      body: "Basic floral garlands and small flower bunches as centerpieces are budget-friendly and charming.",
    },
    {
      header: "Seasonal Flowers",
      body: "Choosing seasonal flowers can save money while still adding freshness to the venue.",
    },
  ];

  const list4 = [
    {
      header: "Fabric Drapes",
      body: "Using colored drapes in hues like red, gold, or ivory keeps the mandap elegant. Zzeeh arranges these drapes creatively to add depth and structure.",
    },
    {
      header: "Traditional Accents",
      body: "Small brass lamps, wooden frames, or clay pots enhance the mandap without high costs.",
    },
  ];

  const list5 = [
    {
      header: "Budget",
      body: "Make sure the planner’s services fit within your budget.",
    },
    {
      header: "Style",
      body: "Does their style align with your vision?",
    },
    {
      header: "Reputation",
      body: "Check reviews and ask for references.",
    },
    {
      header: "Availability",
      body: "Ensure the planner is available on your wedding date.",
    },
  ];

  const list6 = [
    {
      header: "Traditional Seating Options",
      body: "Using mats, cushions, or low seating for guests gives an authentic, comfortable feel.",
    },
    {
      header: "Creative Seating Arrangements",
      body: "Arranging chairs in unique ways, like in a circle or semi-circle around the mandap, creates a beautiful effect without extra decor.",
    },
  ];

  const list7 = [
    {
      header: "Handcrafted Garlands",
      body: "Family members can make flower garlands for entrances or chairs.",
    },
    {
      header: "Personalized Signage",
      body: "Small, hand-painted signs directing guests or sharing wedding details add warmth and personality.",
    },
  ];

  const list8 = [
    {
      header: "Entrance Decor",
      body: "Zzeeh often uses marigold garlands and hanging lamps to welcome guests in a traditional yet cost-effective way.",
    },
    {
      header: "Aisle Decorations",
      body: "Simple floral bunches or petals along the aisle create a beautiful path for the bride without excessive costs.",
    },
  ];

  const list9 = [
    {
      header: "Table Centerpieces",
      body: "Small floral arrangements or candles in brass holders are simple and beautiful.",
    },
    {
      header: "Food Display",
      body: "Aesthetic, organized food displays with traditional utensils bring a homely feel.",
    },
  ];

  const list10 = [
    {
      header: "Drapes and Basic Floral Arrangements",
      body: "Simple drapes paired with flowers add elegance. Zzeeh uses these effectively to keep the stage elegant and budget-friendly.",
    },
    {
      header: "Affordable Backdrops",
      body: "Wooden frames or greenery walls add depth to photos without high costs.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget Hindu Wedding Decor by Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/igtyEtRm9l8?si=NoYyM1r7n40wz7tP"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a beautiful Hindu wedding doesn’t have to mean breaking the
        bank. With{" "}
        <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
          Zzeeh
        </a>{" "}
        Weddings’ expertise in elegant and budget-friendly decor, you can bring
        the grandeur of traditional Hindu aesthetics to life. Let’s explore how
        Zzeeh Weddings creates a memorable ambiance while keeping it
        wallet-friendly.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          When planning a Hindu wedding on a budget, the focus is on keeping it
          elegant yet simple.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          Weddings specializes in budget-friendly decor that doesn’t compromise
          on aesthetics. Let’s look at how they bring the beauty of Hindu
          traditions into your wedding decor while staying within your budget.
        </p>
      </section>

      <section
        id="why-choose-zzeeh-weddings-for-budget-friendly-decor"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings for Budget-Friendly Decor
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          Weddings has extensive experience in crafting wedding decor solutions
          that are beautiful yet affordable. Their approach emphasizes creative
          use of resources and prioritizing decor elements that make a big
          impact. This focus allows them to create budget-conscious weddings
          that still carry the rich traditions of Hindu culture.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/bollywood-style-wedding-planning.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="setting-the-budget-for-wedding-decor" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Setting the Budget for Wedding Decor
        </h2>
        <p>
          The first step is understanding how much you’d like to allocate
          specifically to decor.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          helps by breaking down the decor budget into manageable categories,
          such as floral arrangements, mandap decor, lighting, and seating. This
          ensures that the budget is used effectively, focusing on the most
          impactful elements.
        </p>
      </section>

      <section
        id="traditional-decor-elements-for-hindu-weddings"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Traditional Decor Elements for Hindu Weddings
        </h2>
        <p>
          A traditional Hindu wedding is incomplete without certain key
          elements:
        </p>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <section id="budget-friendly-wedding-theme-ideas" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget-Friendly Wedding Theme Ideas
        </h2>
        <p>
          Some themes can be both stunning and budget-friendly. Here are a few
          suggestions{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          offers:
        </p>
        <DynamicList listType="list-disc" data={list2} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/budget-event-planners-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="floral-arrangements-on-a-budget" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Floral Arrangements on a Budget
        </h2>
        <p>
          Flowers are essential to Hindu weddings, but elaborate arrangements
          can be costly.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          suggests:
        </p>
        <DynamicList listType="list-disc" data={list3} />
      </section>

      <section id="elegant-mandap-decor-on-a-budget" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Elegant Mandap Decor on a Budget
        </h2>
        <p>
          The mandap is the focal point of a Hindu wedding, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          knows how to decorate it beautifully on a budget:
        </p>
        <DynamicList listType="list-disc" data={list4} />
      </section>

      <section id="lighting-arrangements-within-budget" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Lighting Arrangements within Budget
        </h2>
        <p>
          Lighting sets the mood, and there are many affordable ways to create a
          warm glow:
        </p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/pre-wedding-ceremony.webp"
        alt="success-stories"
      />

      <section
        id="reusing-decor-elements-for-different-rituals"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Reusing Decor Elements for Different Rituals
        </h2>
        <p>
          To keep costs down,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          repurposes decor across different wedding rituals. For instance,
          mandap decorations can later serve as part of the stage backdrop,
          while aisle flowers can be rearranged for the reception.
        </p>
      </section>

      <section id="budget-friendly-seating-arrangements" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget-Friendly Seating Arrangements
        </h2>
        <p>Seating decor can also be done on a budget:</p>
        <DynamicList listType="list-disc" data={list6} />
      </section>

      <section id="diy-decor-ideas-for-personal-touch" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          DIY Decor Ideas for Personal Touch
        </h2>
        <p>
          Adding DIY elements is a wonderful way to involve family and friends
          in the decor process:
        </p>
        <DynamicList listType="list-disc" data={list7} />
      </section>

      <section id="simple-entrance-and-aisle-decor" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Simple Entrance and Aisle Decor
        </h2>
        <p>Simple decor can be stunning with the right touches:</p>
        <DynamicList listType="list-disc" data={list8} />
      </section>

      <section
        id="decorating-the-dining-area-on-a-budget"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Decorating the Dining Area on a Budget
        </h2>
        <p>
          The dining area doesn’t need to be over-the-top to look festive.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          offers ideas like:
        </p>
        <DynamicList listType="list-disc" data={list9} />
      </section>

      <section id="minimalist-stage-decor" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Minimalist Stage Decor
        </h2>
        <p>
          The stage where photos are taken should be appealing but doesn’t
          require complex decor:
        </p>
        <DynamicList listType="list-disc" data={list10} />
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          A budget Hindu wedding doesn’t mean compromising on elegance or
          tradition.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          Weddings brings expert planning and creative thinking to offer decor
          solutions that honor the beauty of Hindu culture without straining the
          wallet. With their experience and dedication, your wedding day will be
          filled with beauty, tradition, and cherished memories.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
