import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import WeddingsPage from "./pages/WeddingsPage";
import PageTitle from "./components/ui/PageTitle";
import Privacy from "./pages/policies/Privacy";
import Refund from "./pages/policies/Refund";
import Terms from "./pages/policies/Terms";
import ContactUs from "./pages/ContactUs";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import AboutUs from "./pages/AboutUs";
import ThankYou from "./pages/ThankYou";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <PageTitle title="Zzeeh Weddings | Best Weddings Planners in Bangalore" />
            <MainPage />
          </>
        }
      />
      <Route
        path="/zzeeh_weddings"
        element={
          <>
            <PageTitle title="Weddings by Zzeeh | Best Wedding Planners in Bangalore" />
            <WeddingsPage />
          </>
        }
      />
      <Route
        path="/weddings"
        element={<Navigate to="/zzeeh_weddings" replace />}
      />
      <Route path="/about_us" element={<AboutUs />} />
      <Route
        path="/thank_you"
        element={
          <>
            <PageTitle title="Thank you for contacting Zzeeh Weddings" />
            <ThankYou />
          </>
        }
      />
      <Route
        path="/blogs"
        element={
          <>
            <PageTitle title="Zzeeh Blogs | Best Wedding Planners in Bangalore" />
            <Blogs />
          </>
        }
      />
      <Route
        path="/blogs/:blog_id"
        element={
          <>
            <PageTitle title="Zzeeh Blogs | Best Wedding Planners in Bangalore" />
            <Blog />
          </>
        }
      />

      <Route path="/terms_of_use" element={<Terms />} />
      <Route path="/privacy_policy" element={<Privacy />} />
      <Route path="/refund_policy" element={<Refund />} />
      <Route path="/contact_us" element={<ContactUs />} />

      {/* <Route
        path="/events"
        element={
          <>
            <PageTitle title="Events by Zzeeh | Best Event Planners in Bangalore" />
            <EventsPage />
          </>
        }
      />
      <Route
        path="/gallery"
        element={
          <>
            <PageTitle title="Zzeeh Gallery | Best Wedding and Event Planners in Bangalore" />
            <GalleryPage />
          </>
        }
      /> */}
    </Routes>
  );
};

export default AppRoutes;
