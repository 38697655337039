import React, { useState } from "react";
import { socials } from "../../assets/data/socials";
import { Button, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { emailRegex } from "../../lib/constants";
import { apiClient } from "../../lib/apiClient";

const FollowUs = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      const res = await apiClient.post("/open/users/blogs", {
        email: email,
      });
      // console.log(res);
      if (res?.status === 200 || res.status === 201) {
        toast.success("Subscribed Successfully!");
        setEmail("");
      }
    } catch (error) {
      toast.error("Subscription failed. Please try again later.");
    }
  };

  return (
    <div className="lg:fixed lg:top-1/2 lg:right-0 lg:transform lg:-translate-y-1/2 lg:w-1/3 xl:w-1/4 border-t-[1px] lg:border-t-0 lg:border-s-[1px] pt-10 lg:py-20 xl:px-10 border-yellow space-y-5 p-5">
      <div>
        <p className="text-xl font-medium mb-2">Subscribe to Zzeeh Blog</p>
        <p className="mb-1">
          Curious about how Zzeeh Weddings is revolutionizing unforgettable,
          dreamlike weddings with elegance and innovation?
        </p>
        <p>Subscribe here:</p>
        <div className="flex flex-row space-x-3 lg:flex-col lg:space-y-3 lg:space-x-0 xl:flex-row xl:space-y-0 xl:space-x-3 mt-3">
          <TextInput
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="username@gmail.com"
            required
          />
          <Button
            className="bg-black hover:!bg-gray-800 focus:ring-0"
            onClick={handleSubmit}
          >
            <span className="md:font-medium">Subscribe</span>
          </Button>
        </div>
      </div>
      <div>
        <p className="text-xl font-medium mb-2">Follow Us</p>
        <div className="flex items-center gap-3">
          {socials.map((item, index) => (
            <a
              key={index}
              data-aos="fade-right"
              data-aos-delay={150 * index}
              href={item.url}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-70"
            >
              <item.icon />
            </a>
          ))}
        </div>
      </div>
      <div>
        <p className="font-medium mb-1"> For media queries please contact</p>
        <a
          href="mailto:info@zzeeh.com"
          className="text-gold underline hover:opacity-70"
          target="_blank"
          rel="noreferrer"
        >
          info@zzeeh.com
        </a>
      </div>
    </div>
  );
};

export default FollowUs;
