export const team = [
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/zubair.webp",
    name: "Zubair Abdul Waheed",
    role: "CEO, MD",
    linkedIn: "https://www.linkedin.com/in/zubair-abdul-waheed-16aa6333/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/shaistha.webp",
    name: "Shaistha Hasan",
    role: "COO, Finance Head",
    linkedIn: "https://www.linkedin.com/in/shaistha-hasan-a573ab115/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/mudassir.webp",
    name: "Muhammad Mudassir",
    role: "General Manager Operations",
    linkedIn: "https://www.linkedin.com/in/muhammad-mudassir-t-8a7321161/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/amit.webp",
    name: "Amit Kumar Jain",
    role: "VP of Corporates",
    linkedIn: "https://www.linkedin.com/in/amit-kumar-jain-04bb78111/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/umar.webp",
    name: "Mohammad Umar",
    role: "Production Executive",
    linkedIn: "https://www.linkedin.com/in/mohammed-umar-88241b286/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/syed.webp",
    name: "Syed Zubair Ahmed",
    role: "Production Manager",
    linkedIn: "https://www.linkedin.com/in/syed-zubair-a93b43236/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/aleem.webp",
    name: "Aleem Sharif",
    role: "General Manager Corporates",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/farhan.webp",
    name: "Farhan Ahmed Khan",
    role: "Director of Photography",
    linkedIn: "https://www.linkedin.com/in/farhan-ahmed-khan-a7b563152/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/zain.webp",
    name: "Syed Zainul Abidin",
    role: "Head of Technology",
    linkedIn: "https://www.linkedin.com/in/sza619/",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/habeeb.webp",
    name: "Ar. Mohammad Habeeb",
    role: "Creative Director",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/mahesh.webp",
    name: "Mahesh Raju V S",
    role: "Asst. Manager Accounts",
  },
  {
    imageURL:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/team/tarique.webp",
    name: "Tarique Anjum",
    role: "Performance Marketing Manager",
    linkedIn: "https://www.linkedin.com/in/tariqueak/",
  },
];
