import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./AppRoutes";
import AOS from "aos";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
AOS.init();

function App() {
  return (
    <BrowserRouter>
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
