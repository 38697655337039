import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "overview-of-the-garden-city",
      label: "Overview of the Garden City – A Dream Wedding Destination",
    },
    {
      id: "what-makes-a-top-wedding-planner",
      label: "What Makes a Top Wedding Planner?",
    },
    {
      id: "top-wedding-planners-in-the-garden-city",
      label: "Top Wedding Planners in the Garden City",
    },
    {
      id: "how-to-choose-the-right-wedding-planner",
      label: "How to Choose the Right Wedding Planner",
    },
    {
      id: "benefits-of-hiring-a-local-wedding-planner",
      label: "The Benefits of Hiring a Local Wedding Planner",
    },
    {
      id: "how-wedding-planners-handle-challenges",
      label: "How Wedding Planners Handle Challenges",
    },
    {
      id: "wedding-planner-costs",
      label: "Wedding Planner Costs – What to Expect",
    },
    {
      id: "diy-vs-professional-wedding-planning",
      label: "DIY vs. Professional Wedding Planning",
    },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Why Hiring a Wedding Planner is Essential",
      body: "Planning a wedding is no easy feat. From picking the venue to managing budgets, it’s a long list of to-dos that can cause stress. A wedding planner not only eases this burden but also ensures every detail is executed to perfection.",
    },
    {
      header: "The Role of a Wedding Planner",
      body: "A wedding planner is your go-to person for organizing, coordinating, and troubleshooting. They help you create a timeline, select vendors, manage the budget, and ensure the day runs smoothly. Whether it's helping choose the perfect flowers or making sure your cake arrives on time, they handle it all.",
    },
    {
      header: "How Wedding Planners Bring Your Vision to Life",
      body: "Wedding planners listen to your ideas and turn them into a cohesive event. They use their expertise and connections to make your vision a reality, ensuring you enjoy the journey without being bogged down by the small stuff.",
    },
  ];

  const list2 = [
    {
      header: "Why Couples Choose the Garden City",
      body: "Couples love the Garden City because of its unique combination of serene landscapes and modern amenities. Whether you dream of a grand ballroom affair or a whimsical garden ceremony, this city has it all.",
    },
    {
      header: "Popular Wedding Venues in the Garden City",
      body: "Some of the most sought-after wedding venues include stunning botanical gardens, luxury hotels, and historic estates. Each offers a different vibe, so couples can easily find a venue that matches their personal style.",
    },
  ];

  const list3 = [
    {
      header: "Experience and Expertise",
      body: "Experience matters! A wedding planner with a proven track record can navigate the complexities of organizing a wedding, from vendor negotiations to managing last-minute hiccups.",
    },
    {
      header: "Personalized Approach",
      body: "A great wedding planner listens to your ideas and tailors the wedding to suit your unique personality and preferences. No cookie-cutter weddings here!",
    },
    {
      header: "Creativity and Innovation in Wedding Planning",
      body: "The best wedding planners think outside the box. They come up with innovative ideas to make your wedding stand out and incorporate the latest trends without compromising on your vision.",
    },
  ];

  const list4 = [
    {
      header: "Planner 1: A Dream Wedding Specialist",
      body: "Known for crafting elegant and timeless weddings, Planner 1 has a knack for attention to detail and ensuring that every element, from décor to the guest experience, is flawless.",
    },
    {
      header: "Planner 2: The Creative Visionary",
      body: "If you’re looking for a planner who can bring a unique artistic touch to your wedding, Planner 2 is the one. They specialize in creating personalized weddings with bold designs and creative flair.",
    },
    {
      header: "Planner 3: The Luxurious Experience",
      body: "For couples who want to pull out all the stops, Planner 3 offers luxury wedding planning that includes high-end venues, world-class catering, and extravagant décor that will leave your guests in awe.",
    },
  ];

  const list5 = [
    {
      header: "Budget",
      body: "Make sure the planner’s services fit within your budget.",
    },
    {
      header: "Style",
      body: "Does their style align with your vision?",
    },
    {
      header: "Reputation",
      body: "Check reviews and ask for references.",
    },
    {
      header: "Availability",
      body: "Ensure the planner is available on your wedding date.",
    },
  ];

  const list6 = [
    {
      header: "",
      body: "How many weddings do you handle at once?",
    },
    {
      header: "",
      body: "Can you work within my budget?",
    },
    {
      header: "",
      body: "Do you offer day-of coordination services?",
    },
  ];

  const list7 = [
    {
      header: "Knowledge of Local Venues",
      body: "A local planner will have a deep understanding of the best venues in the area and can help you find hidden gems that fit your style and budget.",
    },
    {
      header: "Relationships with Local Vendors",
      body: "Having a planner with established connections to local vendors means better deals, trusted services, and smoother coordination.",
    },
  ];

  const list8 = [
    {
      header: "Dealing with Budget Constraints",
      body: "Wedding planners are pros at making the most of any budget. They know where to splurge and where to save without sacrificing the quality of your day.",
    },
    {
      header: "Managing Last-Minute Changes",
      body: "If things go awry at the last minute (and they often do), a wedding planner is there to ensure everything is back on track, whether it's dealing with a vendor issue or bad weather.",
    },
  ];

  const list9 = [
    {
      header: "",
      body: "The size of the wedding",
    },
    {
      header: "",
      body: "The complexity of the event",
    },
    {
      header: "",
      body: "The level of service required",
    },
  ];

  const list10 = [
    {
      header: "Average Prices for Wedding Planners in the Garden City",
      body: "On average, full-service wedding planners in the Garden City charge between $3,000 and $10,000, depending on the scope of work.",
    },
  ];

  const list11 = [
    {
      header: "Pros and Cons of DIY Weddings",
      body: "While DIY weddings can save money, they come with a significant amount of stress. You’re responsible for everything from coordinating vendors to ensuring everything runs on schedule.",
    },
    {
      header: "Why Professional Planners Make a Difference",
      body: "Professional planners have the experience and resources to handle all the details, allowing you to enjoy your day without worrying about the logistics.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        From Vision to Reality: Top Wedding Planners in the Garden City
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/Qhu87NyFtgc?si=TR7bMTjW87JvTK3I"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Weddings
          </a>{" "}
          are one of the most special days in a couple’s life. But let’s face
          it, planning a wedding can be overwhelming! This is where wedding
          planners come in, turning your dream wedding into reality. If you’re
          getting married in the Garden City, you’re in luck—this city offers
          some of the top wedding planners in the industry. Let’s dive into
          everything you need to know about finding the best wedding planner and
          how they can make your big day unforgettable.
        </p>
        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="overview-of-the-garden-city" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Overview of the Garden City – A Dream Wedding Destination
        </h1>
        <p>
          The Garden City, known for its lush green spaces and romantic
          settings, has become a favorite for couples tying the knot. It offers
          a perfect blend of urban chic and natural beauty, making it a
          versatile location for any{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          style.
        </p>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/bollywood-style-wedding-planning.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="what-makes-a-top-wedding-planner" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          What Makes a Top Wedding Planner?
        </h2>
        <p>
          Choosing the right{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planner can make or break your wedding experience. Here are the top
          qualities you should look for in a wedding planner.
        </p>
        <DynamicList newLine listType="" data={list3} />
      </section>

      <section
        id="top-wedding-planners-in-the-garden-city"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Planners in the Garden City
        </h2>
        <p>
          Let’s take a look at some of the best{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planners in the Garden City who have a reputation for making dreams
          come true.
        </p>
        <DynamicList newLine listType="" data={list4} />
      </section>

      <section
        id="how-to-choose-the-right-wedding-planner"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          How to Choose the Right Wedding Planner
        </h2>
        <p>
          Choosing a{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planner is a big decision, and you want to make sure you’re selecting
          the right one. Here are some factors to consider:
        </p>
        <DynamicList listType="list-disc" data={list5} />
        <p>Questions to Ask Before Hiring</p>
        <DynamicList noColon listType="list-disc" data={list6} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/budget-event-planners-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section
        id="benefits-of-hiring-a-local-wedding-planner"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Benefits of Hiring a Local Wedding Planner
        </h2>
        <p>
          There’s something special about working with a{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planner who knows the area well. Here’s why hiring a local planner is
          beneficial:
        </p>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <section
        id="how-wedding-planners-handle-challenges"
        className="space-y-3"
      >
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          How Wedding Planners Handle Challenges
        </h2>
        <p>
          No{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          is without its challenges, but a seasoned wedding planner knows how to
          tackle them head-on.
        </p>
        <DynamicList newLine listType="" data={list8} />
      </section>

      <section id="wedding-planner-costs" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Planner Costs – What to Expect
        </h2>
        <p>
          The cost of hiring a{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planner can vary, but understanding what influences the price can help
          you budget more effectively.
        </p>
        <p>What Influences the Cost?</p>
        <DynamicList noColon listType="list-disc" data={list9} />
        <DynamicList newLine listType="" data={list10} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/pre-wedding-ceremony.webp"
        alt="success-stories"
      />

      <section id="diy-vs-professional-wedding-planning" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          DIY vs. Professional Wedding Planning
        </h2>
        <p>
          It’s tempting to plan your{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          yourself, but here’s why hiring a professional planner might be a
          better choice.
        </p>
        <DynamicList newLine listType="" data={list11} />
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          Planning a{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          is a monumental task, but with the right wedding planner by your side,
          your dream wedding can become a reality. From finding the perfect
          venue to ensuring every detail aligns with your vision, a professional{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planner can transform your big day into a truly magical event. If
          you're tying the knot in the Garden City, you're in the best hands
          with its top wedding planners.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
