export const navItems = [
  { name: "Home", link: "" },
  { name: "Weddings", link: "zzeeh_weddings" },
  { name: "About Us", link: "about_us" },
  { name: "Blogs", link: "blogs" },
  { name: "Contact Us", link: "contact_us" },
  // "events",
  // "rentals",
  // "gallery",
];
