import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction to Muslim Weddings",
    },
    {
      id: "why-hire-muslim-wedding-planner",
      label: "Why Hire a Muslim Wedding Planner?",
    },
    {
      id: "top-muslim-wedding-planners-bangalore",
      label: "Top Muslim Wedding Planners in Bangalore",
    },
    {
      id: "essential-elements-muslim-wedding",
      label: "Essential Elements of a Muslim Wedding",
    },
    {
      id: "services-offered-muslim-wedding-planners",
      label: "Services Offered by Muslim Wedding Planners",
    },
    {
      id: "customized-themes-decor-muslim-weddings",
      label: "Customized Themes and Décor for Muslim Weddings",
    },
    {
      id: "venues-muslim-weddings-bangalore",
      label: "Venues for Muslim Weddings in Bangalore",
    },
    {
      id: "important-considerations-planning-muslim-wedding",
      label: "Important Considerations for Planning a Muslim Wedding",
    },
    {
      id: "budget-planning-muslim-wedding",
      label: "Budget Planning for a Muslim Wedding",
    },
    {
      id: "legal-requirements-muslim-wedding-bangalore",
      label: "Legal Requirements for a Muslim Wedding in Bangalore",
    },
    {
      id: "real-wedding-stories-case-studies",
      label: "Real Wedding Stories and Case Studies",
    },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Understanding Cultural and Religious Nuances",
      body: "Muslim weddings require a deep understanding of Islamic traditions, such as the Nikah ceremony, recitation of the Quran, and the exchange of vows. A planner ensures that these are carried out with the right etiquette and reverence.",
    },
    {
      header: "Managing Complexities of Traditional Ceremonies",
      body: "From organizing the Mehndi ceremony to planning the Valima reception, wedding planners help coordinate each event seamlessly, ensuring all traditions are respected.",
    },
    {
      header: "Ensuring a Smooth and Stress-Free Wedding",
      body: "With professional expertise, wedding planners take the burden of logistics, vendor coordination, and event execution off the couple and their families, allowing them to enjoy the special day.",
    },
  ];

  const list2 = [
    {
      header: "Popular Planners",
      body: "Renowned for their ability to plan grand, memorable weddings while respecting the Islamic rituals.",
    },
    {
      header: "Experience in Islamic Weddings",
      body: "These planners have a track record of organizing successful Muslim weddings with attention to every detail—from the religious aspects to the décor and entertainment.",
    },
  ];

  const list3 = [
    {
      header: "Nikah Ceremony",
      body: "This is the central event, where the couple officially marries in the presence of family and a religious officiant (Qazi). The couple exchanges vows and signs the Nikahnama, making their union legal in the eyes of Islam.",
    },
    {
      header: "Mehndi (Henna) Ceremony",
      body: "Traditionally held the day before the wedding, this event is all about celebrating the bride. The bride’s hands and feet are adorned with intricate henna designs.",
    },
    {
      header: "Valima",
      body: "The wedding reception hosted by the groom’s family. It’s a grand event where family and friends come together to celebrate the union.",
    },
    {
      header: "Haldi and Sangeet",
      body: "While not originally part of traditional Islamic customs, these events have become popular in many modern Muslim weddings, especially in India. The Haldi ceremony involves applying turmeric paste to the bride and groom, and the Sangeet is a musical evening with performances and dancing.",
    },
  ];

  const list4 = [
    {
      header: "Venue Selection",
      body: "Planners help couples choose the right venue that reflects the desired style and accommodates guests comfortably.",
    },
    {
      header: "Islamic Wedding Décor",
      body: "From traditional to modern Islamic themes, planners work with decorators to create stunning spaces that adhere to the couple’s vision while incorporating Islamic elements like Arabic calligraphy, moon, and star motifs.",
    },
    {
      header: "Catering",
      body: "Offering Halal-certified food is essential in a Muslim wedding. Planners collaborate with caterers to create menus that meet dietary requirements and serve a variety of traditional and modern dishes.",
    },
    {
      header: "Guest Management",
      body: "Managing guest lists, seating arrangements, and hospitality are handled with great care, ensuring everyone is comfortable.",
    },
    {
      header: "Bridal Styling and Grooming",
      body: "The bride and groom’s attire, typically traditional Islamic garments like the bridal Sharara or Lehenga and the groom’s Sherwani, are selected with the help of stylists who understand Muslim wedding traditions.",
    },
    {
      header: "Photography and Videography",
      body: "Wedding planners ensure that photographers and videographers capture every significant moment, from the Nikah to the final farewell.",
    },
    {
      header: "Entertainment",
      body: "Planners arrange for traditional Qawwali performances or even modern Sufi music and DJ nights to keep guests entertained.",
    },
  ];

  const list5 = [
    {
      header: "Traditional Themes",
      body: "Incorporating Islamic motifs like Arabic calligraphy, gold accents, and royal décor elements.",
    },
    {
      header: "Modern Islamic Themes",
      body: "A contemporary twist on traditional aesthetics with minimalist décor, soft lighting, and elegant color palettes.",
    },
    {
      header: "Color Schemes and Floral Arrangements",
      body: "From rich gold and green combinations to pastel and white tones, planners work with florists and decorators to create the perfect look.",
    },
  ];

  const list6 = [
    {
      header: "Banquet Halls and Hotels",
      body: "Popular choices for large gatherings that offer grand spaces with all amenities.",
    },
    {
      header: "Outdoor Venues",
      body: "For couples looking to host their wedding amidst nature, Bangalore has several garden venues and farmhouses that provide a scenic backdrop.",
    },
    {
      header: "Heritage Venues",
      body: "Palatial and heritage venues are perfect for those looking to add a royal touch to their wedding.",
    },
  ];

  const list7 = [
    {
      header: "Gender-Segregated Spaces",
      body: "Many traditional Muslim weddings have separate seating arrangements for men and women.",
    },
    {
      header: "Prayer Times and Religious Observances",
      body: "Ensuring the wedding timeline respects prayer times and includes provisions for religious observances.",
    },
  ];

  const list8 = [
    {
      header: "Traditional Nikah Ceremony",
      body: "Hosted in a grand banquet hall with classical Islamic décor and separate seating arrangements.",
    },
    {
      header: "Modern Islamic Wedding",
      body: "Featuring a combination of traditional elements and contemporary style, this wedding included both a Mehndi night and a grand Valima reception.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Muslim Wedding Planners in Bangalore: A Comprehensive Guide
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/htZs7PYuGIU?si=Qt7Mj1r7SghZzJPf"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Muslim Weddings
        </h1>
        <p>
          Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>
          , also known as Nikah, are deeply rooted in Islamic traditions and
          cultural heritage. They are not only sacred ceremonies but also a
          beautiful celebration of love and unity between families. The
          significance of a Muslim wedding lies in the religious rituals,
          customs, and prayers that make the day memorable and meaningful. In
          Bangalore, Muslim weddings are known for blending traditional elements
          with modern influences, creating a unique and vibrant atmosphere.
        </p>
        <p>
          Given the complexity and richness of these ceremonies, hiring a
          specialized Muslim wedding planner can be a game-changer. A planner
          who understands the cultural and religious nuances ensures that the
          event flows smoothly, respecting all the Islamic traditions while
          reflecting the couple’s personal style.
        </p>
      </section>

      <section id="why-hire-muslim-wedding-planner" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Hire a Muslim Wedding Planner?
        </h1>
        <p>
          Planning a Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>{" "}
          involves managing several intricate rituals, ceremonies, and religious
          practices. Here’s why hiring a professional Muslim wedding planner is
          crucial:
        </p>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <section id="top-muslim-wedding-planners-bangalore" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Muslim Wedding Planners in Bangalore
        </h1>

        <p>
          Bangalore boasts a number of wedding planners who specialize in Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>
          . These planners are known for their expertise in creating both
          traditional and contemporary weddings that cater to Islamic customs.
        </p>
        <DynamicList listType="list-disc" data={list2} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-reception-decor-zzeehwedding.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="essential-elements-muslim-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Essential Elements of a Muslim Wedding
        </h1>
        <p>
          Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>{" "}
          are comprised of various events, each with its own importance:
        </p>
        <DynamicList listType="list-disc" data={list3} />
      </section>

      <section
        id="services-offered-muslim-wedding-planners"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Services Offered by Muslim Wedding Planners
        </h1>
        <p>
          Hiring a specialized{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planner ensures that every detail of the wedding is planned to
          perfection. Here are the services typically offered:
        </p>
        <DynamicList listType="list-disc" data={list4} />
      </section>

      <section
        id="customized-themes-decor-muslim-weddings"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Customized Themes and Décor for Muslim Weddings
        </h1>
        <p>
          Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planners in Bangalore specialize in creating personalized themes for
          couples. Some popular themes include:
        </p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-indian-wedding-planner.webp"
        alt="budget-decor-ideas"
      />

      <section id="venues-muslim-weddings-bangalore" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Venues for Muslim Weddings in Bangalore
        </h1>
        <p>
          Bangalore offers a wide range of venues that cater to Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>
          :
        </p>
        <DynamicList listType="list-disc" data={list6} />
      </section>

      <section
        id="important-considerations-planning-muslim-wedding"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Important Considerations for Planning a Muslim Wedding
        </h1>
        <p>
          Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>{" "}
          come with specific religious protocols and cultural sensitivities that
          must be adhered to:
        </p>
        <DynamicList listType="list-disc" data={list7} />
      </section>

      <section id="budget-planning-muslim-wedding" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget Planning for a Muslim Wedding
        </h1>
        <p>
          Muslim wedding planners are skilled at managing budgets and offering
          cost-effective solutions without compromising on style or quality.
          From luxurious{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>{" "}
          to budget-friendly options, they help balance the couple’s desires
          with practical considerations.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/marriages-decoration.webp"
        alt="success-stories"
      />

      <section
        id="legal-requirements-muslim-wedding-bangalore"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Legal Requirements for a Muslim Wedding in Bangalore
        </h1>
        <p>
          The Nikahnama, a marriage contract, is a crucial document in a Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>
          . After the Nikah, the marriage needs to be registered with the
          authorities to make it legally binding in India.
        </p>
      </section>

      <section id="real-wedding-stories-case-studies" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Real Wedding Stories and Case Studies
        </h1>
        <p>Some inspiring examples include:</p>
        <DynamicList listType="list-disc" data={list8} />
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Hiring a specialized Muslim{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>{" "}
          planner in Bangalore ensures that your big day is not only beautiful
          but also adheres to the religious and cultural customs of Islam.
          Whether you’re planning a traditional Nikah or a more contemporary
          celebration, a professional planner can bring your dream wedding to
          life while allowing you to focus on enjoying the journey.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
