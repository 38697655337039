import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "intro", label: "Introduction" },
    { id: "zzeeh-advantage", label: "The Zzeeh Weddings Advantage" },
    { id: "personal-planning", label: "Personalized Wedding Planning" },
    { id: "packages", label: "Comprehensive Wedding Packages" },
    { id: "expert-team", label: "Expert Team of Professionals" },
    { id: "venue-selection", label: "Venue Selection Assistance" },
    { id: "decor-ambiance", label: "Decor and Ambiance Creation" },
    { id: "catering-menu", label: "Catering and Menu Options" },
    { id: "entertainment", label: "Entertainment and Guest Experience" },
    { id: "photo-video", label: "Photography and Videography" },
    { id: "destination-weddings", label: "Destination Weddings with Zzeeh" },
    { id: "eco-friendly", label: "Eco-Friendly and Sustainable Weddings" },
    { id: "budget-options", label: "Budget-Friendly Options" },
    { id: "testimonials", label: "Customer Testimonials" },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Dream Weddings Made Reality: A 2024 Review of Zzeeh Weddings' Planning
        Services
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=ZOVXfP_zm_Efh1lv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Planning a wedding is a monumental task filled with countless details,
          from choosing the right venue to finding the perfect decor. Enter{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>
          , a premier wedding planning service that turns dreams into reality by
          crafting unforgettable events tailored to each couple’s unique vision.
          In this review, we’ll dive deep into why Zzeeh Weddings is a top
          choice for couples planning their big day in 2024, exploring their
          exceptional services and what makes them stand out.
        </p>
      </section>

      <section id="zzeeh-advantage" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Zzeeh Weddings Advantage
        </h1>
        <p>
          Choosing{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          offers the advantage of working with a company that values both
          quality and customer satisfaction. They understand that weddings are a
          once-in-a-lifetime experience, and their commitment to excellence
          shines through in every wedding they plan. Known for attention to
          detail, Zzeeh’s planners are dedicated to bringing even the smallest
          wedding details to life.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/strategies-to-plan-a-surprise-party-with-the-help-of-event-planners.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="personal-planning" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Personalized Wedding Planning
        </h2>
        <p>
          Every couple has a unique story, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          believes that each wedding should reflect that. Offering tailored
          planning, Zzeeh’s team takes the time to understand each couple’s
          preferences, style, and vision. With customizable packages, couples
          can pick and choose services that best fit their needs, making the
          entire planning process smooth and enjoyable.
        </p>
      </section>

      <section id="packages" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Comprehensive Wedding Packages
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          offers a variety of wedding packages designed to suit diverse needs
          and budgets. Whether couples are looking for a small, intimate
          ceremony or a grand celebration, Zzeeh’s packages are equipped with
          everything from decor to vendor coordination. These all-in-one
          solutions ensure a hassle-free experience, allowing couples to focus
          on what matters most – celebrating their love.
        </p>
      </section>

      <section id="expert-team" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Expert Team of Professionals
        </h2>
        <p>
          The Zzeeh team comprises seasoned professionals, including skilled
          planners and on-site coordinators who manage each aspect of the event.
          Their expertise allows couples to relax on their big day, knowing that
          every detail is in capable hands. The team's experience, creativity,
          and dedication are key factors in delivering exceptional events time
          after time.
        </p>
      </section>

      <section id="venue-selection" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Venue Selection Assistance
        </h2>
        <p>
          Finding the perfect venue is a critical part of wedding planning, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has partnerships with top venues, offering options for all types of
          weddings – whether it’s a beachfront affair, a luxurious ballroom, or
          a scenic outdoor venue. With Zzeeh’s assistance, couples can discover
          and select venues that match their style and budget.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="decor-ambiance" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Decor and Ambiance Creation
        </h2>
        <p>
          The ambiance of a wedding can set the tone for the entire celebration.
          Zzeeh Weddings’ decor team goes above and beyond to create stunning
          spaces that reflect each couple's personality. From elegant floral
          arrangements to thematic lighting, Zzeeh ensures that every detail
          contributes to a beautiful and memorable setting.
        </p>
      </section>

      <section id="catering-menu" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Catering and Menu Options
        </h2>
        <p>
          Food is an essential part of any celebration, and Zzeeh offers a
          variety of catering options tailored to each couple’s taste and
          preferences. From multi-course meals to fusion cuisine, the catering
          team works with clients to design menus that delight guests. They also
          offer tasting sessions, so couples can experience the flavors
          firsthand and make adjustments as needed.
        </p>
      </section>

      <section id="entertainment" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Entertainment and Guest Experience
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          knows that the guest experience is crucial to a memorable wedding.
          Offering a range of entertainment options – from live bands to DJs and
          interactive activities – they ensure every guest is engaged and
          enjoying the celebration. Their team also manages logistics, so all
          entertainment elements flow seamlessly throughout the event.
        </p>
      </section>

      <section id="photo-video" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Photography and Videography
        </h2>
        <p>
          Capturing every moment is a priority for most couples, and Zzeeh
          Weddings has partnered with top photographers and videographers who
          specialize in storytelling through images. Their team ensures that
          each smile, tear, and dance move is captured, creating a timeless
          collection of memories for couples to cherish forever.
        </p>
      </section>

      <section id="destination-weddings" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Destination Weddings with Zzeeh
        </h2>
        <p>
          For couples dreaming of a destination wedding,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          offers planning for various beautiful locations. From tropical beaches
          to historic sites, Zzeeh handles the logistics, accommodations, and
          local vendor coordination. This allows couples to enjoy the excitement
          of a destination wedding without the stress of managing remote
          planning.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/how-do-you-plan-a-destination-wedding.webp"
        alt="success-stories"
      />

      <section id="eco-friendly" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Eco-Friendly and Sustainable Weddings
        </h2>
        <p>
          As sustainability becomes a priority,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          offers eco-friendly wedding options, integrating sustainable practices
          in areas like decor and vendor selection. By choosing green practices,
          couples can celebrate their love responsibly, knowing they’re reducing
          their wedding’s environmental impact.
        </p>
      </section>

      <section id="budget-options" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget-Friendly Options
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          offers budget-friendly packages that don’t sacrifice quality. By
          working with couples to create a beautiful celebration within their
          means, Zzeeh ensures that every wedding is memorable without breaking
          the bank. Flexible payment options are also available, making
          high-quality wedding planning accessible for all.
        </p>
      </section>

      <section id="testimonials" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Customer Testimonials
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has a wealth of positive reviews from couples who have experienced
          their expertise firsthand. From meticulous planning to exceptional
          execution, these testimonials highlight how Zzeeh surpasses
          expectations, creating unforgettable weddings that perfectly reflect
          each couple’s dreams.
        </p>
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh Weddings
          </a>{" "}
          has earned a reputation as one of the leading wedding planners in
          2024, with a focus on personalization, high-quality service, and
          customer satisfaction. Their comprehensive offerings and dedication to
          every detail make them a top choice for couples looking to make their
          dream wedding a reality.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
