import React from "react";

const GetInTouch = () => {
  return (
    <section id="contact-zzeeh" className="space-y-3">
      <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
        Get in Touch with Zzeeh Weddings
      </h1>
      <ul className="list-disc ml-5 px-5 py-2 space-y-1">
        <li>
          <span className="font-semibold">Website: </span>{" "}
          <a href="/" className="hover:text-gold">
            www.zzeehweddings.com
          </a>
        </li>
        <li>
          <span className="font-semibold">Contact: </span>{" "}
          <a href="mailto:info@zzeeh.com" className="hover:text-gold">
            info@zzeeh.com
          </a>
        </li>
        <li>
          <span className="font-semibold">Phone: </span>{" "}
          <a href="tel:+919110466643" className="hover:text-gold">
            +91 91104 66643
          </a>
        </li>
      </ul>
    </section>
  );
};

export default GetInTouch;
