import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction to Wedding Planning in Bangalore",
    },
    {
      id: "importance-of-hiring-a-wedding-planner",
      label: "Importance of Hiring a Wedding Planner",
    },
    {
      id: "top-wedding-venues",
      label: "Top Wedding Venues in Bangalore",
    },
    {
      id: "creative-wedding-themes",
      label: "Creative Wedding Themes",
    },
    {
      id: "destination-weddings",
      label: "Destination Weddings in Bangalore",
    },
    {
      id: "catering-services",
      label: "Catering Services for Weddings",
    },
    {
      id: "wedding-photography-videography",
      label: "Wedding Photography and Videography",
    },
    {
      id: "bridal-wear-groom-outfits",
      label: "Bridal Wear and Groom Outfits",
    },
    {
      id: "guest-accommodations",
      label: "Managing Guest Accommodations",
    },
    {
      id: "entertainment-performances",
      label: "Entertainment and Performances",
    },
    {
      id: "budgeting-financial-planning",
      label: "Budgeting and Financial Planning",
    },
    {
      id: "eco-friendly-wedding-ideas",
      label: "Eco-friendly Wedding Ideas",
    },
    {
      id: "legal-aspects",
      label: "Legal Aspects of Weddings in Bangalore",
    },
    {
      id: "cultural-religious-ceremonies",
      label: "Managing Cultural and Religious Ceremonies",
    },
    {
      id: "faqs",
      label: "FAQs",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Luxurious Venues",
      body: "For couples looking to host a grand wedding, Bangalore offers some of the most luxurious venues, such as Taj West End, ITC Gardenia, and The Leela Palace. These venues are perfect for extravagant celebrations, offering top-notch services, sprawling lawns, and opulent ballrooms.",
    },
    {
      header: "Budget-Friendly Venues",
      body: "If you're planning a wedding on a budget, Bangalore has a plethora of affordable yet beautiful venues. Options like Temple Tree Leisure, The Peacock Grove, and Ganjam Mantapa provide picturesque settings without breaking the bank.",
    },
  ];

  const list2 = [
    {
      header: "Traditional Themes",
      body: "In Bangalore, traditional weddings are full of color, culture, and rituals. Many wedding planners specialize in traditional themes that incorporate regional customs, such as Kannada, Tamil, or North Indian rituals. From elaborate mandap decorations to authentic South Indian cuisine, traditional-themed weddings are steeped in heritage.",
    },
    {
      header: "Contemporary Themes",
      body: "For couples looking for something modern and unique, contemporary themes are popular in Bangalore. Think minimalist decor, fusion food, and unconventional venues like art galleries or rooftop settings. Planners offer a variety of creative options that go beyond the typical.",
    },
  ];

  const list3 = [
    {
      header: "Traditional Cuisine",
      body: "A wedding in Bangalore isn't complete without delectable South Indian cuisine. Top caterers offer elaborate spreads of traditional dishes, including dosas, idlis, biryanis, and authentic sweets like Mysore Pak and jalebis.",
    },
    {
      header: "Multi-cuisine Catering",
      body: "For couples who want to cater to a diverse group of guests, Bangalore wedding planners can arrange multi-cuisine menus, blending North Indian, South Indian, Continental, and even international cuisines.",
    },
  ];

  const list4 = [
    {
      header: "Custom Designs",
      body: "Wedding attire is one of the most exciting parts of planning. Bangalore is home to numerous designers who offer custom bridal lehengas, sarees, and sherwanis. From intricate embroidery to rich fabrics, there are endless options for bespoke wedding outfits.",
    },
    {
      header: "Designer Boutiques",
      body: "Bangalore also has a thriving fashion scene with designer boutiques like Deepika Govind and Tarun Tahiliani offering stunning bridal collections. Planners can arrange appointments with top designers to ensure that the couple looks their best.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Bangalore Wedding Planners: Different categories and importance
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/jV3iKj4ilNs?si=zVSRI-ebs8Dy9BnJ"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Weddings are one of the most memorable occasions in life, and planning
        them can be both thrilling and stressful. In Bangalore, a city known for
        its vibrant culture and dynamic lifestyle, wedding planners are crucial
        in turning dreams into reality. Whether it's the grandeur of the venue,
        the elegance of decor, or the precision in timelines, Bangalore has some
        of the most professional and creative wedding planners. If you're
        looking to organize a wedding here, these 10 essential topics can help
        you find the right wedding planner and guide you through the process.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Wedding Planning in Bangalore
        </h1>
        <p>
          Bangalore is a bustling metropolis that offers a perfect blend of
          traditional and modern cultures, making it an ideal city for a
          wedding. The city boasts a variety of wedding venues, services, and
          talented professionals who can make any wedding dream come true.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Wedding
          </a>{" "}
          planners here not only handle the logistics but also bring creative
          ideas to life, whether you're looking for a lavish affair or an
          intimate celebration.
        </p>
      </section>

      <section
        id="importance-of-hiring-a-wedding-planner"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Importance of Hiring a Wedding Planner
        </h1>
        <p>
          Planning a wedding can be overwhelming, especially with so many
          details to consider. A wedding planner takes the stress off your
          shoulders by coordinating everything from the venue to the guest list.
          They ensure that your big day runs smoothly, allowing you to focus on
          enjoying the moment.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Weddings
          </a>{" "}
          planners in Bangalore are known for their professionalism, creativity,
          and ability to handle weddings of all scales.
        </p>
      </section>

      <section id="top-wedding-venues" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Venues in Bangalore
        </h1>

        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="creative-wedding-themes" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creative Wedding Themes
        </h1>

        <DynamicList newLine listType="" data={list2} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/how-to-plan-a-destination-wedding-on-a-small-budget.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="destination-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Destination Weddings in Bangalore
        </h1>
        <p>
          Though Bangalore is an urban city, it's surrounded by picturesque
          locations perfect for destination{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>
          . Planners can organize stunning ceremonies at nearby hill stations
          like Nandi Hills or resorts around Mysore and Coorg, offering a serene
          backdrop away from the city hustle.
        </p>
      </section>

      <section id="catering-services" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Catering Services for Weddings
        </h1>

        <DynamicList newLine listType="" data={list3} />
      </section>

      <section id="wedding-photography-videography" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Photography and Videography
        </h1>
        <p>
          Capturing the special moments of a wedding is crucial, and Bangalore
          boasts some of the best wedding photographers and videographers.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Wedding
          </a>{" "}
          planners often work with top professionals who specialize in
          everything from candid shots to traditional photo sessions.
        </p>
      </section>

      <section id="bridal-wear-groom-outfits" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Bridal Wear and Groom Outfits
        </h1>

        <DynamicList newLine listType="" data={list4} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/low-budget-wedding-services-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="guest-accommodations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Managing Guest Accommodations
        </h1>
        <p>
          For couples hosting out-of-town guests,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planners can manage all the details of guest accommodations. From
          luxury hotels to serviced apartments, Bangalore has a wide range of
          options to ensure guests are comfortable.
        </p>
      </section>

      <section id="entertainment-performances" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Entertainment and Performances
        </h1>
        <p>
          Entertainment is a key part of any wedding, and Bangalore{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planners are adept at arranging live performances, from DJs and live
          bands to traditional dance performances like Bharatanatyam or Kathak.
        </p>
      </section>

      <section id="budgeting-financial-planning" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budgeting and Financial Planning
        </h1>
        <p>
          Sticking to a budget is essential for any wedding, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planners help couples manage their finances by providing a clear
          breakdown of costs. They ensure that every aspect of the wedding is
          covered without exceeding the budget.
        </p>
      </section>

      <section id="eco-friendly-wedding-ideas" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Eco-friendly Wedding Ideas
        </h1>
        <p>
          Sustainability is becoming an important consideration for many
          couples. Eco-friendly{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            weddings
          </a>{" "}
          in Bangalore might include using biodegradable decor, zero-waste
          catering, and locally sourced flowers.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/black-and-white-photos.webp"
        alt="success-stories"
      />

      <section id="legal-aspects" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Legal Aspects of Weddings in Bangalore
        </h1>
        <p>
          Marriage laws in India can vary by state, so it's essential to
          understand the legal requirements for a{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          in Bangalore. Wedding planners can assist with all the paperwork, from
          marriage registration to obtaining necessary permits for the event.
        </p>
      </section>

      <section id="cultural-religious-ceremonies" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Managing Cultural and Religious Ceremonies
        </h1>
        <p>
          Bangalore is a melting pot of cultures, and{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            wedding
          </a>{" "}
          planners here are experienced in managing a variety of cultural and
          religious ceremonies. Whether it's a Hindu, Christian, Muslim, or
          interfaith wedding, they ensure that all traditions are respected and
          seamlessly incorporated.
        </p>
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQ)
        </h1>

        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              How much do wedding planners in Bangalore charge?
            </p>
            <p>
              <a
                href="/zzeeh_weddings"
                className="text-blue-500 hover:underline"
              >
                Wedding
              </a>{" "}
              planner fees can vary widely depending on the scale and complexity
              of the wedding. Expect to pay anywhere from INR 1 lakh to 5 lakhs
              or more, depending on services.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How early should I book a wedding planner in Bangalore?
            </p>
            <p>
              It’s best to book a wedding planner at least 6 to 12 months before
              your wedding to ensure availability and ample planning time.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can wedding planners help with destination weddings outside
              Bangalore?
            </p>
            <p>
              Yes, many{" "}
              <a
                href="/zzeeh_weddings"
                className="text-blue-500 hover:underline"
              >
                wedding
              </a>{" "}
              planners in Bangalore specialize in organizing destination
              weddings in nearby areas like Coorg, Mysore, and even abroad.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What are some popular wedding venues in Bangalore?
            </p>
            <p>
              Popular venues include Taj West End, ITC Gardenia, and Temple Tree
              Leisure. There are also many beautiful outdoor venues like
              farmhouses and resorts.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Is it possible to have an eco-friendly wedding in Bangalore?
            </p>
            <p>
              Absolutely! Many planners now offer eco-friendly{" "}
              <a
                href="/zzeeh_weddings"
                className="text-blue-500 hover:underline"
              >
                wedding
              </a>{" "}
              options, including sustainable decor, organic food, and waste
              reduction practices.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Hiring a wedding planner in Bangalore is one of the best decisions you
          can make to ensure your special day goes off without a hitch. From
          managing the guest list to ensuring the perfect decor, wedding
          planners handle it all, allowing you to enjoy your wedding
          stress-free. With so many options for venues, themes, and services,
          Bangalore is a perfect destination for dream weddings.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
