import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "intro", label: "Introduction" },
    { id: "why-budget", label: "Why Choose a Budget-Friendly Wedding?" },
    { id: "about-zzeeh", label: "Who is Zzeeh Weddings?" },
    { id: "venue-selection", label: "Selecting the Perfect Venue" },
    { id: "custom-decor", label: "Importance of Customizing the Décor" },
    { id: "guest-list", label: "Streamlined Guest List" },
    { id: "catering", label: "Efficient Catering Solutions" },
    { id: "pre-wedding", label: "Pre-Wedding Events on a Budget" },
    { id: "photo-video", label: "Affordable Photography & Videography" },
    { id: "dress-attire", label: "Dress and Attire Within Budget" },
    {
      id: "local-vendors",
      label: "Hiring Local Vendors for Additional Services",
    },
    { id: "decor-cost", label: "How Zzeeh Manages Decoration Costs" },
    { id: "invitations", label: "Creative Invitations" },
    { id: "tech-budget", label: "The Role of Technology in Budget Planning" },
    { id: "conclusion", label: "Final Thoughts on Budget Muslim Weddings" },
  ];

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget Muslim Weddings in Bangalore by Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/-c1Z7IGOdro?si=LqCmvDfmONsQVnks"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a wedding is an exciting journey, but it can often come with a
        hefty price tag. However, in Bangalore,{" "}
        <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
          Zzeeh
        </a>{" "}
        Weddings has made it possible for couples to celebrate their special day
        on a budget without compromising on elegance and tradition. In this
        article, we’ll dive into how to plan a budget-friendly Muslim wedding in
        Bangalore with the help of Zzeeh Weddings. From venue selection to
        décor, catering, and more, let’s explore ways to create a memorable
        wedding that aligns with your budget.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Planning a wedding is both thrilling and overwhelming, especially when
          balancing tradition with budget constraints. For Muslim families in
          Bangalore,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          Weddings offers the perfect blend of cultural elegance and
          affordability. They help couples create cherished memories without
          putting a financial strain on their future.
        </p>
      </section>

      <section id="why-budget" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose a Budget-Friendly Wedding?
        </h1>
        <p>
          The benefits of a budget-friendly wedding go beyond cost savings; it’s
          a mindful approach that allows families to focus on what truly
          matters. With the right planning, couples can begin their new life
          together financially secure, ready to invest in the future instead of
          debt.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/dress-appropriate.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="about-zzeeh" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Who is Zzeeh Weddings?
        </h2>
        <p>
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          Weddings has earned a reputation for transforming simple weddings into
          grand celebrations within a reasonable budget. Their expertise in
          organizing budget-conscious Muslim weddings in Bangalore includes
          everything from venue selection to catering, décor, and logistics.
        </p>
      </section>

      <section id="venue-selection" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Selecting the Perfect Venue
        </h2>
        <p>
          Venue selection is often one of the biggest wedding expenses. With{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>
          ’s guidance, couples can choose from a variety of affordable options
          in Bangalore, such as community halls, smaller banquet spaces, or
          outdoor areas. Tips like opting for weekday bookings and using minimal
          décor can help reduce costs.
        </p>
      </section>

      <section id="custom-decor" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Importance of Customizing the Décor
        </h2>
        <p>
          The beauty of a Muslim wedding lies in the simplicity and elegance of
          its décor. With cost-effective floral arrangements, drapes, and
          Islamic-themed touches,{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          helps create a sophisticated ambiance. They focus on subtle yet
          elegant decorations that reflect Islamic traditions while staying
          within budget.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/affordable-wedding-venues.webp"
        alt="budget-decor-ideas"
      />

      <section id="guest-list" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Streamlined Guest List
        </h2>
        <p>
          Keeping a smaller, intimate guest list allows for a more meaningful
          celebration and significantly reduces expenses.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          encourages couples to focus on close family and friends, which not
          only enhances the personal atmosphere but also allows for
          higher-quality experiences within the set budget.
        </p>
      </section>

      <section id="catering" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Efficient Catering Solutions
        </h2>
        <p>
          Food is central to any celebration, especially in weddings.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          provides budget-friendly catering options that include traditional
          Muslim dishes. From biryani to kebabs, the menu can be customized to
          fit the budget without compromising on flavor and presentation.
        </p>
      </section>

      <section id="pre-wedding" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Pre-Wedding Events on a Budget
        </h2>
        <p>
          The Mehendi, Haldi, and Nikah ceremonies are cherished parts of a
          Muslim wedding, and each can be arranged on a budget.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>
          ’s expertise lies in simplifying these events by choosing intimate
          settings and selective decorations that retain the charm of each event
          without overspending.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Go-digital-wedding-photographer.webp"
        alt="success-stories"
      />

      <section id="photo-video" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Affordable Photography & Videography
        </h2>
        <p>
          Capturing memories is essential, but it doesn’t have to be
          extravagant.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          partners with affordable photographers and videographers who are
          skilled at capturing the essence of the day without a hefty price tag.
          Simple packages focusing on key moments can deliver beautiful results.
        </p>
      </section>

      <section id="dress-attire" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Dress and Attire Within Budget
        </h2>
        <p>
          Muslim bridal and groom attire can look stunning without splurging.
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          suggests local designers in Bangalore who offer quality,
          budget-friendly outfits. By choosing elegant yet cost-effective
          options, couples can achieve a traditional look that aligns with their
          budget.
        </p>
      </section>

      <section id="local-vendors" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Hiring Local Vendors for Additional Services
        </h2>
        <p>
          Choosing local vendors helps reduce costs and supports the community.
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          provides a list of reliable, budget-friendly vendors in Bangalore,
          covering everything from makeup artists to decorators, making it easy
          for couples to find affordable services.
        </p>
      </section>

      <section id="decor-cost" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          How Zzeeh Manages Decoration Costs
        </h2>
        <p>
          Decoration is essential, but it can be simplified to meet budget
          constraints.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          uses creative strategies like minimal floral arrangements and reusable
          décor items to reduce expenses while still delivering a beautiful
          setup.
        </p>
      </section>

      <section id="invitations" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creative Invitations
        </h2>
        <p>
          Invitations set the tone for the wedding.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          offers digital invitations, a budget-friendly and eco-friendly option,
          alongside budget-friendly printed invitations for those who prefer a
          tangible touch.
        </p>
      </section>

      <section id="tech-budget" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          The Role of Technology in Budget Planning
        </h2>
        <p>
          Using apps and budgeting tools can streamline the wedding planning
          process.{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>{" "}
          guides couples in using these tools to track expenses, compare vendor
          prices, and stay within their budget. Technology support from Zzeeh
          helps ensure efficient planning without surprises.
        </p>
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Final Thoughts on Budget Muslim Weddings
        </h2>
        <p>
          Planning a budget Muslim wedding in Bangalore is entirely achievable
          with{" "}
          <a href="/zzeeh_weddings" className="text-blue-500 hover:underline">
            Zzeeh
          </a>
          ’s help. They combine a deep understanding of tradition with
          budget-conscious strategies, creating a special day that respects both
          cultural heritage and financial considerations. A budget wedding
          doesn’t mean sacrificing quality; it’s about making meaningful choices
          that create lasting memories.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
